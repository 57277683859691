const menu = [
  // Dashboard (Chart)
  {
    icon: 'mdi-chart-areaspline',
    title: 'dashboard',
    roles: ['admin', 'niveau0'],
    to: '/dashboard',
  },
  // Opération
  {
    group: '/operation',
    icon: 'mdi-cogs',
    title: 'operation',
    roles: ['admin', 'niveau0'],
    children: [
      {
        title: 'client',
        roles: ['admin', 'niveau0'],
        to: 'client',
      },
      {
        title: 'contract',
        roles: ['admin', 'niveau0'],
        to: 'contrat',
      },
      {
        title: 'billing',
        roles: ['admin', 'niveau0'],
        to: 'facture',
      },
      {
        title: 'order',
        roles: ['admin', 'niveau0'],
        to: 'commande',
      },
      {
        title: 'tools',
        roles: ['admin', 'niveau0'],
        to: 'tools',
      },
    ],
  },
  // Autre
  {
    group: '/other',
    icon: 'mdi-database-settings',
    title: 'other',
    roles: ['admin'],
    children: [
      {
        title: 'parameters',
        roles: ['admin'],
        to: 'parameters',
      },
      {
        title: 'messages',
        roles: ['admin'],
        to: 'messages',
      },
      {
        title: 'list',
        roles: ['admin'],
        to: 'list',
      },
      {
        title: 'toggles',
        roles: ['admin'],
        to: 'toggles',
      },
    ],
  },
  // Users Management
  {
    group: '/usersmanagement',
    icon: 'mdi-account-multiple',
    title: 'usermanagement',
    roles: ['admin'],
    children: [
      {
        title: 'users',
        roles: ['admin'],
        to: 'users',
      },
    ],
  },
]

export default menu
